import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
const SectionTitleComponent = styled.div(() => ({
    display: 'flex',
}));
const SectionTitleText = styled.span(() => ({
    flex: '0 0 auto',
}));
const SectionTitleFiller = styled.span((props) => ({
    display: 'flex',
    flex: '1 1 auto',
    height: '1px',
    margin: '0px 2px 4px',
    alignSelf: 'end',
    backgroundImage: 'linear-gradient(to right, #7E868C 10%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 2px',
    backgroundRepeat: 'repeat-x',
}));
const SectionTitleValue = styled.span(() => ({
    flex: '0 0 auto',
    alignSelf: 'start',
}));
const SectionTitle = ({ title = null, value = null }) => (_jsxs(SectionTitleComponent, { children: [_jsx(SectionTitleText, { children: title }), _jsx(SectionTitleFiller, {}), _jsx(SectionTitleValue, { children: value })] }));
export default SectionTitle;
